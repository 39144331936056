import React, { useState, useEffect, useRef } from "react";
import Footer from "../common/Footer";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Popover } from "antd";
import { BarLoader } from "react-spinners";
import { jwtDecode } from "jwt-decode";

const SignupForm = () => {
  const otpInputRef = useRef(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    country: "",
    profileType: "",
    check: false,
    referredBy: "",
    otp: "",
    nominatedNGO: "",
    token: "",
  });
  const [loading, setLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [disbtn, setdisbtn] = useState(false);
  const [otpSent, setotpSent] = useState(true);
  const [users, setUsers] = useState([]);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (show && otpInputRef.current) {
      otpInputRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [show]);

  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/listusers?org=yes`,
    };

    axios
      .request(config)
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadOpenUser = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/openuser?email=${formData?.email}`,
    };

    axios
      .request(config)
      .then((response) => {
        setFormData({
          ...formData,
          lastName: response?.data?.success?.lastName,
          firstName: response?.data?.success?.firstName,
          referredBy: response?.data?.success?.referredBy,
          profileType: response?.data?.success?.profileType,
          country: response?.data?.success?.country,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        loadData();
        // loadOpenUser();
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getQueryParams = () => {
      const params = {};
      const queryString = window.location.search.substring(1);
      const pairs = queryString.split("&");
      for (let pair of pairs) {
        const [key, value] = pair.split("=");
        params[decodeURIComponent(key)] = decodeURIComponent(value || "");
      }
      return params;
    };

    if (token) {
      setFormData({
        ...formData,
        token: token,
        firstName: jwtDecode(token).name,
        email: jwtDecode(token).email,
      });
    } else {
      const queryParams = getQueryParams();
      console.log(queryParams.email);
      setFormData({
        ...formData,
        lastName: queryParams.lastName || "",
        firstName: queryParams.firstName || "",
        referredBy: queryParams.referredBy || "",
        profileType: queryParams.profileType,
        country: queryParams.country,
        email: queryParams.email,
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    setLoading(true);

    let data = JSON.stringify({ email: formData.email, otp: formData.otp });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/verifiedregister`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data.status) {
          await Swal.fire({
            title: "Success!",
            text: "Account Created Successfully!",
            confirmButtonColor: "#3ca151",
            icon: "success",
          });
          localStorage.setItem("userToken", response.data?.userToken);
          localStorage.setItem("firstLogin", true);
          navigate("/dashboard");
        } else {
          Swal.fire({
            title: "Wrong OTP",
            text: "Please Try Again",
            confirmButtonColor: "#3ca151",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
        console.log(response);
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Wrong OTP",
          text: "Please Try Again",
          confirmButtonColor: "#3ca151",
          icon: "error",
          confirmButtonText: "Okay",
        });
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = JSON.stringify({
      firstName: formData.firstName,
      lastName: formData.lastName,
      businessName: formData.businessName,
      email: formData.email,
      country: formData.country,
      profileType: formData.profileType,
      referredBy: formData.referredBy,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/openregister`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.status) {
          Swal.fire({
            title: "Success!",
            text: "OTP sent successfully!",
            confirmButtonColor: "#3ca151",
            icon: "success",
          });
          setdisbtn(true);
          setshow(true);
          setLoading(false);
          setTimeout(() => {
            setotpSent(false);
          }, 30000);
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error!",
          confirmButtonColor: "#3ca151",
          text: error.response?.data.message,
          icon: "error",
        });
        setLoading(false);
      });
  };

  const handleGoogleSubmit = () => {
    setLoading(true);
    let data = JSON.stringify(formData);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/googleregister`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          localStorage.setItem("userToken", response.data?.userToken);
          navigate("/dashboard");
          Swal.fire({
            title: "Success!",
            text: "Account Created Successfully!",
            confirmButtonColor: "#3ca151",
            icon: "success",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error in Authenticating",
          text: "Please Try Again",
          confirmButtonColor: "#3ca151",
          icon: "error",
          confirmButtonText: "Okay",
        });
      });
  };

  return (
    <>
      <div className="container">
        <div className="ch-cotainer">
          <div className="login-video-box">
            <iframe
              src="https://www.youtube.com/embed/mWRnhw1hSzo?si=twrU4XCg2HkDKc3L"
              title="YouTube video player"
              height="300"
              width={500}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            <h4 style={{ textAlign: "center", backgroundColor: "transparent" }}>
              Learn How To Use Donor Money
            </h4>
            <p style={{ textAlign: "center", backgroundColor: "transparent" }}>
              This video shows how to use donor money
              <br /> for NGOs and Donors.
            </p>
          </div>
        </div>
        <div className="ch-cotainer form">
          <div className="login-form-box">
            <form onSubmit={handleSubmit}>
              <select
                id="profileType"
                name="profileType"
                value={formData.profileType}
                onChange={handleChange}
                required
              >
                <option value={""}>Select profile type*</option>
                <option value={"business"}>Business</option>
                <option value={"impactOrg"}>Impact Organisation</option>
                <option value={"individual"}>Individual</option>
              </select>

              {formData.profileType === "business" && (
                <>
                  <input
                    placeholder="Enter Business Name*"
                    type="text"
                    id="businessName"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                  />
                </>
              )}

              {(formData.profileType === "ngo" ||
                formData.profileType === "impactOrg") && (
                <>
                  <input
                    placeholder="Enter Organisation Name*"
                    type="text"
                    id="businessName"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                  />
                </>
              )}

              <input
                placeholder="Enter your first name*"
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />

              <input
                placeholder="Enter your last name"
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />

              <input
                placeholder="Enter a valid email*"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                disabled={formData.token}
                required
              />

              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="">Select your country*</option>
                <option value="USA">USA</option>
                <option value="India">India</option>
                <option value="Somalia">Somalia</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegovina">
                  Bosnia and Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cabo Verde">Cabo Verde</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Democratic Republic of the Congo">
                  Democratic Republic of the Congo
                </option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor (Timor-Leste)">
                  East Timor (Timor-Leste)
                </option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Eswatini">Eswatini</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Greece">Greece</option>
                <option value="Grenada">Grenada</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-Bissau">Guinea-Bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Honduras">Honduras</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Ivory Coast">Ivory Coast</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, North (North Korea)">
                  Korea, North (North Korea)
                </option>
                <option value="Korea, South (South Korea)">
                  Korea, South (South Korea)
                </option>
                <option value="Kosovo">Kosovo</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia">Micronesia</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="North Macedonia">North Macedonia</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Qatar">Qatar</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Kitts and Nevis">
                  Saint Kitts and Nevis
                </option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Vincent and the Grenadines">
                  Saint Vincent and the Grenadines
                </option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">
                  Sao Tome and Principe
                </option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Sudan">South Sudan</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City">Vatican City</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>

              <select
                onChange={(e) =>
                  setFormData({ ...formData, nominatedNGO: e.target.value })
                }
              >
                <option value="" selected>
                  Nominate beneficiary Organisation
                </option>
                {users?.map((u) => (
                  <option value={u._id}>{u?.businessName}</option>
                ))}
              </select>
              <Popover
                id="popOver"
                placement="leftBottom"
                content={
                  <p
                    style={{
                      backgroundColor: "white !important",
                      color: "white",
                    }}
                  >
                    Select an NGO or Impact organisation <br />
                    who should receive your auto donation.
                    <br /> This can be updated later <br />
                    from the edit profile section.
                  </p>
                }
                trigger="hover"
              >
                <IoMdInformationCircleOutline
                  id="popoverbtn"
                  size={20}
                  color="green"
                  style={{
                    position: "relative",
                    backgroundColor: "transparent",
                    left: "90%",
                    bottom: "48px",
                  }}
                />
              </Popover>

              <input
                placeholder="Enter referral code (optional)"
                type="text"
                id="referredBy"
                name="referredBy"
                value={formData.referredBy}
                onChange={handleChange}
              />

              <div id="privacyCheckBox" for="privacy_policy_checkbox">
                <input
                  type="checkbox"
                  style={{ fontSize: "10px !imprtant" }}
                  onChange={() =>
                    setFormData({ ...formData, check: !formData.check })
                  }
                  value={formData.check}
                  id="privacy_policy_checkbox"
                />
                <p>
                  I have read the{" "}
                  <a href="/privacypolicy" target="_blank">
                    Privacy policy
                  </a>
                  .
                </p>
              </div>

              {formData.token ? (
                <button type="button" onClick={handleGoogleSubmit}>
                  Proceed
                </button>
              ) : disbtn ? (
                <>
                  <button disabled={otpSent} type="submit">
                    Resend OTP
                  </button>
                  <p style={{ fontSize: "12px", textAlign: "center" }}>
                    Please wait for 30 seconds before requesting a new OTP.
                  </p>
                </>
              ) : (
                <button type="submit">Register</button>
              )}
            </form>

            {show && (
              <form style={{ marginTop: "30px" }} onSubmit={handleSubmitOtp}>
                <p>
                  One Time Password (OTP) sent to your email.
                  <br />
                  Please check your email including the spam folder.
                  <br />
                  OTP is valid for 30 minutes.
                </p>
                {
                  //<label htmlFor="pin">Enter OTP:</label>
                }
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  placeholder="Enter OTP"
                  value={formData.otp}
                  onChange={handleChange}
                  required
                />
                <button type="submit">Login</button>{" "}
              </form>
            )}

            {loading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "transparent !important",
                  marginTop: "25px",
                }}
              >
                <BarLoader color="#3ca151" />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SignupForm;
