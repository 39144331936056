import React, { useState, useEffect } from "react";
import axios from "axios";
import Ngo from "../ngo/Ngo";
import { useNavigate } from "react-router-dom";
import AdminDashboard from "../admin/AdminDashboard";
import ShowRewards from "../modals/ShowReward";
const Dashboard = () => {
  const userToken = localStorage.getItem("userToken");
  const firstLogin = localStorage.getItem('firstLogin');
  const [profile, setProfile] = useState("");
  const [showPopup, setShowPopup] = useState('false');
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState('false');

console.log(firstLogin)




  useEffect(() => {
    !userToken && navigate("/");
    
  }, []);

  

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setProfile(response?.data?.data?.profileType);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userToken]);

  return (
    <>
      {(() => {
        switch (profile) {
          case "admin":
            return <AdminDashboard />;
          case "impactOrg":
            return <Ngo />;
          case "ngo":
            return <Ngo />;
          case "individual":
            return <Ngo />;
          case "business":
            return <Ngo />;

          default:
            navigate("/");
            return null;
        }
      })()}
      {firstLogin === 'true' &&
       <ShowRewards
        id={1} 
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />
}
    </>
  );
};

export default Dashboard;
