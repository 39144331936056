import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";

const VerifyToken = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    token: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const loadParams = async () => {
    const getQueryParams = () => {
      const params = {};
      const queryString = window.location.search.substring(1);
      const pairs = queryString.split("&");
      for (let pair of pairs) {
        const [key, value] = pair.split("=");
        params[decodeURIComponent(key)] = decodeURIComponent(value || "");
      }
      setFormData({
        email: params.email,
        token: params.token,
      });
    };

    await getQueryParams();
  };

  useEffect(() => {
    if (formData.email && formData.token) {
      handleSubmit();
    }
  }, [formData]);
  

  useEffect(() => {
    loadParams();
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    let data = JSON.stringify(formData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/verifiedregister`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        if (response.data.status) {
          await Swal.fire({
            title: "Success!",
            text: "Account Verified Successfully!",
            confirmButtonColor: "#3ca151",
            icon: "success",
          });
          localStorage.setItem("userToken", response.data?.userToken);
          localStorage.setItem("firstLogin", true);
          navigate("/dashboard");
        } else {
          Swal.fire({
            title: "Invalid Token",
            text: "Please Try Again",
            confirmButtonColor: "#3ca151",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
        console.log(response);
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Invalid Token",
          text: "Please Try Again",
          confirmButtonColor: "#3ca151",
          icon: "error",
          confirmButtonText: "Okay",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent !important",
            marginTop: "100px",
          }}
        >
          <BarLoader height={"50px"} width={"500px"} color="#3ca151" />
        </div>
      )}
    </div>
  );
};

export default VerifyToken;
