// DisputePage.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Footer from "../common/Footer";

const Feedback = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    description: "",
  });
  const userToken = localStorage.getItem("userToken");
  const [btn, setBtn] = useState(false);
  
  useEffect(() => {
    const loadData = () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_API}/user`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setFormData({
            firstName: response.data?.data.firstName,
            lastName: response.data?.data.lastName,
            email: response.data?.data.email,
            });
        })
        .catch((error) => {});
    };
    loadData();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtn(true);
    let data = JSON.stringify(formData);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/feedback`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          title: "Success!",
          confirmButtonColor: "#3ca151",
          text: "Feedback shared successfully!",
          icon: "success",
        });
        window.location.reload();
      })
      .catch(async (error) => {
        await Swal.fire({
          title: "Error!",
          confirmButtonColor: "#3ca151",
          text: "Something went wrong! Please try again later.",
          icon: "error",
        });
        setBtn(false);
      });
  };

  return (
    <>
      <div
        style={{ width: "50%", margin: "100px auto", paddingBottom: "20px" }}
      >
        <h1>Share your feedback with us</h1>
        <form onSubmit={handleSubmit}>
          <label>First Name:</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
          <br />
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
          <br />

          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <br />
  
          <label>Message:</label>
          <textarea
            rows={"10"}
            name="description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
          <br />
          <button disabled={btn||formData.firstName===""||formData.email===""||formData.description===""} type="submit">Submit</button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Feedback;
