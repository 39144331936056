import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

const Currency = () => {
  const [currencyData, setCurrencyData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_LOCAL_API}/currency`)
      .then((response) => {
        setCurrencyData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="currency-table">
      <h2>Price History</h2>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Currency Value</th>
          </tr>
        </thead>
        <tbody>
          {currencyData.map((currency, index) => (
            <tr key={index}>
              <td>{moment(currency.startDate).format('DD-MM-YYYY HH:MM A')}</td>
              <td>{currency.value.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Currency;
