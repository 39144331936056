import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Footer from "../common/Footer";
import axios from "axios";
import Swal from "sweetalert2";
import { BarLoader } from "react-spinners";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
];

const EmailEditor = () => {
  const [body, setBody] = useState("");
  const [email, setEmail] = useState({ subject: "", name: "", to: [], id: "" });
  const [temps, setTemps] = useState([]);

  const [loading, setLoading] = useState(false);
  const userToken = localStorage.getItem("userToken");

  const loadTemps = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/getalltemps`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setTemps(response.data.templates);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    loadTemps();
  }, []);

  const handleTempSave = async () => {
    setLoading(true);

    let data = JSON.stringify({
      subject: email?.subject,
      body: body,
      name: email?.name,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/emailtemplate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Email Template saved successfully!",
          confirmButtonColor: "#3ca151",
          icon: "success",
        });
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error in saving email",
          text: "Please Try Again",
          confirmButtonColor: "#3ca151",
          icon: "error",
          confirmButtonText: "Okay",
        });
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    setLoading(true);

    let data = JSON.stringify({
      to: email?.to,
      subject: email?.subject,
      body: body,
      name: email?.name,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/sendmail`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          title: "Success!",
          text: "Email sent successfully!",
          confirmButtonColor: "#3ca151",
          icon: "success",
        });
        setLoading(false);
        window.location.reload();
      })
      .catch(async (error) => {
        await Swal.fire({
          title: "Error in sending email",
          text: "Please Try Again",
          confirmButtonColor: "#3ca151",
          icon: "error",
          confirmButtonText: "Okay",
        });
        setLoading(false);
        window.location.reload();
      });
  };

  const handleUpdate = async () => {
    setLoading(true);

    let data = JSON.stringify({
      id: email?.id,
      subject: email?.subject,
      body: body,
      name: email?.name,
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/emailtemplate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        await Swal.fire({
          title: "Success!",
          text: "Email Template updated successfully!",
          confirmButtonColor: "#3ca151",
          icon: "success",
        });
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error in saving email",
          text: "Please Try Again",
          confirmButtonColor: "#3ca151",
          icon: "error",
          confirmButtonText: "Okay",
        });
        setLoading(false);
      });
  };

  const handleDelete = async () => {
    setLoading(true);

    let data = JSON.stringify({
      tempId: email?.id,
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/emailtemplate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then(async(response) => {
       await  Swal.fire({
          title: "Success!",
          text: "Email Template deleted successfully!",
          confirmButtonColor: "#3ca151",
          icon: "success",
        });
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error in deleting email",
          text: "Please Try Again",
          confirmButtonColor: "#3ca151",
          icon: "error",
          confirmButtonText: "Okay",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div style={{ width: "95%", margin: "50px auto", paddingBottom: "20px" }}>
        <h1 id="head_dash">Email Editor</h1>
        <div style={{ marginBottom: "20px" }}>
          <select
            onChange={(e) => {
              const t = JSON.parse(e.target.value);
              console.log(t);
              setEmail({
                ...email,
                subject: t?.subject,
                name: t?.name,
                id: t?._id,
              });
              setBody(t?.body);
            }}
          >
            <option value={JSON.stringify({ subject: "", name: "", _id: "" })}>
              New Template
            </option>
            {temps.map((m) => (
              <option value={JSON.stringify(m)}>{m.name}</option>
            ))}
          </select>

          {loading && <BarLoader color="#3ca151" />}
          <br />
          <label
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Template Name:
            <input
              type="text"
              value={email ? email.name : ""}
              onChange={(e) => {
                setEmail({ ...email, name: e.target.value });
              }}
              style={{
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                marginTop: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                fontSize: "16px",
              }}
              placeholder="Enter name of the template"
            />
          </label>

          <label
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            To:
            <input
              type="text"
              onChange={(e) => {
                const mails = e.target.value
                  .split(",")
                  .map((mail) => mail.trim());
                setEmail({ ...email, to: mails });
              }}
              style={{
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                marginTop: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                fontSize: "16px",
              }}
              placeholder="Enter email addresses separated by commas"
            />
          </label>
          <label
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Email Subject:
            <input
              type="text"
              value={email.subject}
              onChange={(e) => setEmail({ ...email, subject: e.target.value })}
              style={{
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
                marginTop: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                fontSize: "16px",
              }}
              placeholder="Email subject"
            />
          </label>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Body:
            <ReactQuill
              theme="snow"
              value={body}
              onChange={setBody}
              modules={modules}
              formats={formats}
              style={{ height: "300px", marginBottom: "40px" }}
            />
          </label>
        </div>
        <button
          onClick={handleSubmit}
          style={{
            padding: "10px 20px",
            margin: "20px 5px",
            fontSize: "16px",
          }}
        >
          Send
        </button>

        {email.id === "" && (
          <button
            onClick={handleTempSave}
            style={{
              padding: "10px 20px",
              margin: "20px 5px",
              fontSize: "16px",
            }}
          >
            Save as Template
          </button>
        )}
        {email?.id && (
          <>
            <button
              onClick={handleUpdate}
              style={{
                padding: "10px 20px",
                margin: "20px 5px",
                fontSize: "16px",
              }}
            >
              Update Template
            </button>
            <button
              onClick={handleDelete}
              style={{
                padding: "10px 20px",
                margin: "20px 5px",
                fontSize: "16px",
                backgroundColor:"red"
              }}
            >
              Delete Template
            </button>
          </>
        )}
      </div>
      <Footer />{" "}
    </>
  );
};

export default EmailEditor;
