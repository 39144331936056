import React from "react";
import Footer from "../common/Footer";

const Notfound = () => {
  return (
    <>
      <div style={{ background: "black", padding: "5%", height: "75vh" }}>
        <div className="privacy-policy">
          <h1>404 Not Found</h1>
          <p>Sorry, the page you are looking for could not be found.</p>
          <p>
            You can <a href="/">return to the homepage</a>.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Notfound;
