import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import Footer from "../common/Footer";

const AdminFeedback = () => {
  const [disputes, setDisputes] = useState([]);
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    fetchDisputes();
  }, []);

  const fetchDisputes = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/allfeedbacks`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setDisputes(response.data.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong! Please try again later.",
          confirmButtonColor: "#3ca151",
          icon: "error",
        });
      });
  };

  return (
    <>
      <div
        style={{ width: "95%", margin: "50px auto", paddingBottom: "20px" }}
      >
        <h1 id="head_dash">Recieved Feedbacks</h1>
        <div className="table">
          <table>
            <thead>
              <tr>
              <th style={{ width: "10%" }}>Date</th>
                <th style={{ width: "10%" }}>Fullname</th>
                <th style={{ width: "10%" }}>Email</th>
                <th style={{ width: "35%" }}>Description</th>
              </tr>
            </thead>
            <tbody>
              {disputes?.map((dispute) => (
                <tr key={dispute._id}>
                  <td>{moment(dispute.createdDate).format('DD-MM-YYYY hh:mm A')}</td>
                  
                  <td>
                    {dispute.firstName} {dispute.lastName}
                  </td>
                  <td>{dispute.email}</td>
                  <td>{dispute.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default AdminFeedback;
