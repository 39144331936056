import React from "react";
import Footer from "../common/Footer";

const PrivacyPolicy = () => {
  return (
    <>
    <div style={{ background: "black",padding:'5%' }}>
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>Last updated: 05 APRIL 2024</p>
        <p>
          Donor Money, accessible from{" "}
          <a href="https://www.donor.money">www.donor.money</a>, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by Donor Money and how we use it.
        </p>

        <h2>1. Information We Collect:</h2>
        <p>
          We may collect personal information from you when you register for an
          account, make donations, or interact with our platform. This
          information may include:
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Contact information</li>
          <li>Donation history</li>
        </ul>

        <h2>2. How We Use Your Information:</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide and improve our services</li>
          <li>Process transactions and donations</li>
          <li>Communicate with you about your account and transactions</li>
          <li>Personalize your user experience</li>
          <li>Analyze usage trends and improve our platform</li>
          <li>Comply with legal obligations</li>
        </ul>

        <h2>3. Information Sharing:</h2>
        <p>We may share your personal information with:</p>
        <ul>
          <li>
            Service providers and partners who assist us in operating our
            platform
          </li>
          <li>
            Legal authorities or regulatory bodies if required by law or to
            protect our rights
          </li>
          <li>
            Other users as necessary to facilitate transactions and donations
          </li>
        </ul>

        <h2>4. Data Security:</h2>
        <p>
          We implement appropriate security measures to protect your personal
          information from unauthorized access, disclosure, alteration, or
          destruction. However, no method of transmission over the internet or
          electronic storage is entirely secure, and we cannot guarantee
          absolute security.
        </p>

        <h2>5. Your Choices:</h2>
        <p>
          You may update or delete your account information at any time by
          accessing your account settings. You may also opt-out of receiving
          promotional communications from us by following the instructions in
          the communication or contacting us directly.
        </p>

        <h2>6. Changes to this Privacy Policy:</h2>
        <p>
          We may update this Privacy Policy from time to time by posting the
          revised version on our platform. Your continued use of the platform
          after any changes indicates your acceptance of the updated Privacy
          Policy.
        </p>

        <h2>7. Contact Us:</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at{" "}
          <a href="mailto:hello@ie.money">hello@ie.money</a>.
        </p>

        <p>
          This Privacy Policy aims to inform users about how their personal
          information is handled on the Donor Money platform and their rights
          regarding privacy and data protection. Adjustments can be made based
          on specific legal requirements and jurisdictional considerations.
        </p>
      </div>
    </div>
    
    <Footer/>
    </>
  );
};

export default PrivacyPolicy;
