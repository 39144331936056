import React, { useEffect, useState } from "react";
import axios from "axios";
import CreateUserModal from "../modals/CreateUser";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import EditUserModal from "../modals/EditUser";
import { useNavigate } from "react-router-dom";
import Footer from "../common/Footer";

const MyUsers = () => {
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const [ngos, setNgos] = useState([]);
  const [user, setUser] = useState({});
  const [showEdit, setShowEdit] = useState(false);
 

  const openModal = () => {
    setShowEdit(true);
  };

  const closeModal = () => {
    setShowEdit(false);
  };

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.data?.profileType !== "ngo"&&response?.data?.data?.profileType !== "impactOrg") {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const loadUsersData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/myusers`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setNgos(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
    loadUsersData();
  },[]);



  return (
    <>
      <div style={{ margin: "100px auto" }}>
        <h2 id="head_dash" style={{ margin: "30px 200px" }}>
          Dashboard
        </h2>
        <div className="shadow-box" style={{ width: "70%", margin: "auto" }}>
          <h3>Create New User!</h3>
          <CreateUserModal ngo={true} userId={user._id} />
        </div>
        <div className="shadow-box admin-box">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h3>My Individuals</h3>
          </div>
          <hr style={{ marginBottom: "20px" }} />
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>POC</th>
                  <th>Business Name</th>
                  <th>Available DM</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>*</th>
                </tr>
              </thead>
              <tbody>
                {ngos?.map((ngo, index) => (
                  <tr key={index}>
                    <td>
                      {ngo.user?.firstName} {ngo.user?.lastName}
                    </td>
                    <td>
                      {ngo.user?.businessName}
                    </td>
                    
                    <td>
                      {ngo.wallet?.walletPoint?.toLocaleString(undefined, {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                    </td>
                    <td>{ngo.user?.email}</td>
                    <td>{ngo.user?.phone}</td>
                    <td>
                      {/*<MdDelete
                    onClick={() => handleDelete(ngo.user._id)}
                    size={20}
                    style={{ cursor: "pointer" }}
                  />
            */}
                      <FaEdit
                        size={20}
                        onClick={openModal}
                        style={{ cursor: "pointer" }}
                      />
                      <EditUserModal
                        userId={ngo.user?._id}
                        userToken={userToken}
                        onClose={closeModal}
                        showEdit={showEdit}
                        isAdmin={false}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MyUsers;
