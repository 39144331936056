import React, { useEffect } from "react";
import Modal from "react-modal";

const BuyDM = ({ isModalOpen, setIsModalOpen }) => {
  useEffect(() => {
    if (isModalOpen) {
      // Load PayPal SDK dynamically
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=BAAuNoo7XD3cjJghsvL2XaMcKB0M0ZzfCDIr-qZqINiPGfi0cp7nQsy8DNiV7zfV_fzz5ZHuOlgY2qtt-I&components=buttons&enable-funding=venmo&currency=USD";
      script.async = true;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);

      // Initialize PayPal Buttons once the script is loaded
      script.onload = () => {
        if (window.paypal) {
          window.paypal.Buttons({
            createOrder: function (data, actions) {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: "10.00", // Set the amount for the purchase
                    },
                  },
                ],
              });
            },
            onApprove: function (data, actions) {
              return actions.order.capture().then(function (details) {
                alert("Transaction completed by " + details.payer.name.given_name);
              });
            },
            onError: (err) => {
              console.error("PayPal Button Error:", err);
            },
          }).render("#paypal-container-2B2FXPQHQV2HQ"); // Render the button inside this div
        }
      };

      // Clean up script on modal close
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Buy DM"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          color: "#fff",
          backgroundColor: "#2c2c2c",
          borderRadius: "10px",
          padding: "20px",
          maxWidth: "600px",
          margin: "50px auto",
          maxHeight: "80vh", // Ensures it won't take more than 80% of viewport height
          overflowY: "auto", // Adds scroll if the content is too long
          position: "relative",
        },
      }}
    >
      <div className="bg-transparent">

      <button
        onClick={() => setIsModalOpen(false)}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "transparent",
          border: "none",
          fontSize: "20px",
          color: "#fff",
          cursor: "pointer",
        }}
      >
        &times;
      </button>

      <h1>Buy DM</h1>

      {/* PayPal Button Container */}
      <div id="paypal-container-2B2FXPQHQV2HQ"></div>
      </div>
    </Modal>
  );
};

export default BuyDM;
