import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../common/Footer";
import Swal from "sweetalert2";
import SendcoinsOpen from "./SendcoinsOpen";
import EditUserModal from "../modals/EditUser";
import OrgLogo from "../../assets/Organization.png";
import IndLogo from "../../assets/Individual.png";
import AdmLogo from "../../assets/Admin.png";

function ProfileView() {
  const [user, setUser] = useState({});
  const { userName } = useParams();
  const [profile, setProfile] = useState("");
  const [cuser, setCuser] = useState({});
  const userToken = localStorage.getItem("userToken");
  const [showEdit, setShowEdit] = useState(false);
  const openModal = (user) => {
    setShowEdit(true);
  };

  const closeModal = () => {
    setShowEdit(false);
  };

  const loadUser = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/viewuser/${userName}`,
    };

    axios
      .request(config)
      .then((response) => {
        setUser(response?.data?.data);
        switch (response?.data?.data.profileType) {
          case "individual":
            setProfile("Individual");
            break;

          case "ngo":
            setProfile("NGO");
            break;

          case "business":
            setProfile("Business");
            break;

          case "impactOrg":
            setProfile("Impact Organisation");
            break;

          default:
            break;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadCurrentUser = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCuser(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadUser();
    loadCurrentUser();
  }, []);

  return (
    <>
      <div className="pcontainer">
        <div className="profile-header">
          <h1 id="head_dash">Profile-user view</h1>
        </div>
        <div className="profile-content">
          <div className="profile-info">
            <img className="circular-image"
              src={
                user?.profilePic ||
                (user?.profileType === "admin"
                  ? AdmLogo
                  : user?.profileType === "individual"
                  ? IndLogo
                  : OrgLogo)
              }
              alt="profile"
              style={{ height: "60px", margin: "5px" }}
            />
            <EditUserModal
              userId={user?._id}
              userToken={userToken}
              onClose={closeModal}
              showEdit={showEdit}
              isAdmin={false}
            />
            {(user?.profileType === "impactOrg" ||
              user?.profileType === "business" ||
              user?.profileType === "ngo") && <h2>{user?.businessName}</h2>}
            {(user?.profileType === "individual" ||
              user?.profileType === "admin") && (
              <h2>
                {user?.firstName} {user?.lastName}
              </h2>
            )}

            <p>{profile}</p>
            {user._id === cuser._id && (
              <button
                style={{ marginLeft: "10px", fontSize: "14px" }}
                onClick={openModal}
              >
                Edit Profile
              </button>
            )}
            <div className="profile-about">
              {user?.about && <h3>About</h3>}
              <p>{user?.about} </p>
              <h3>Contact Information</h3>

              {(user?.profileType === "business" ||
                user?.profileType === "ngo" ||
                user?.profileType === "impactOrg") && (
                <p>
                  <strong>Contact Name:</strong>{" "}
                  {`${user?.firstName} ${user?.lastName}`}
                </p>
              )}
              <p>
                <strong>Country:</strong> {user?.country}
              </p>
              {user?.website && (
                <p>
                  <strong>Website:</strong>
                  <a href={user?.website}>{user?.website}</a>
                </p>
              )}
            </div>
          </div>
          <div className="featured-blogs">
            <SendcoinsOpen user={user} />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ProfileView;
