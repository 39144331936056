import React from "react";
import { useNavigate } from "react-router-dom";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="hfD">
      <div className="hfDb">
        <div className="hfDbA">
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className="hfDbAaBlack"
          >
            <div className="hfDbAa" onClick={() => navigate("/")}></div>
            <div
              className="hfDbAaBlack"
              style={{ color: "white", marginTop: "20px" }}
            >
              Powering Impact Investments, Natural
              <br />
              Capital and Team Humanity
            </div>
            <div
              className="hfDbAaBlack"
              style={{ color: "white", marginTop: "20px" }}
            >
              Colorado, USA
              <br />
            </div>
          </div>
          <div id="lower-footer" style={{ textAlign: "right", backgroundColor: "black" }}>
          <a
              style={{backgroundColor: "black" }}
              href="/dispute"
              target="_blank"
            >
            Get Support</a> 
            <br/><br/>
            Got some suggestions? &nbsp;
            <a
              style={{ backgroundColor: "black" }}
              href="/feedback"
              target="_blank"
            >
              Give Feedback
            </a>
            <br />
            <br/>
            Contact us at: <a href="mailto:hello@ie.money">hello@ie.money</a>
          </div>
        </div>
        <div className="hfDbB">
          <FaSquareXTwitter
            className="hfDbBa"
            onClick={() => window.open("https://twitter.com/iemoneyhq")}
          />
          <FaLinkedin
            className="hfDbBa"
            onClick={() =>
              window.open("https://www.linkedin.com/company/impact-ecosystem/")
            }
          />
          <AiFillInstagram
            className="hfDbBa"
            onClick={() => window.open("https://www.instagram.com/ie_money/")}
          />

          <FaYoutube
            className="hfDbBa"
            onClick={() =>
              window.open("https://www.youtube.com/@ImpactEcosystem")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
