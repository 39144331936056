import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import moment from "moment";

const ShowDonations = ({id,setIsModalOpen,isModalOpen}) => {
   const [trans, setTrans] = useState([]);
  
  useEffect(() => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_API}/donations?id=${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setTrans(response.data.transactions);
        })
        .catch((error) => {
        });

  }, []);

  const handleViewTransactions = (user) => {
    setIsModalOpen(true);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Top Donations"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          color: "#fff",
          backgroundColor: "#2c2c2c",
          borderRadius: "10px",
          padding: "20px",
          maxWidth: "600px",
          margin: "50px auto",
        },
      }}
    >
      <h2 style={{ marginBottom: "20px", color: "#90e172",backgroundColor:'transparent' }}>Top Donations</h2>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th
              style={{
                textAlign: "left",
                padding: "10px",
                borderBottom: "1px solid #444",
              }}
            >
              Donor
            </th>
            <th
              style={{
                textAlign: "right",
                padding: "10px",
                borderBottom: "1px solid #444",
              }}
            >
              Amount (DM)
            </th>
            <th
              style={{
                textAlign: "right",
                padding: "10px",
                borderBottom: "1px solid #444",
              }}
            >
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          {trans?.map((donation, index) => (
            <tr key={index}>
              <td style={{ padding: "10px", borderBottom: "1px solid #444" }}>
                {donation?.senderId?.businessName||`${donation?.senderId?.firstName} ${donation?.senderId?.lastName}`}
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "right",
                  borderBottom: "1px solid #444",
                }}
              >
                {donation.amount.toLocaleString(undefined, {
                  minimumFractionDigits: 3,
                  maximumFractionDigits: 3,
                })}{" "}
                DM
              </td>
              <td
                style={{
                  padding: "10px",
                  textAlign: "right",
                  borderBottom: "1px solid #444",
                }}
              >
                {moment(donation.createdDate).format('DD-MM-YYYY hh:mm A') }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        style={{ marginTop: "20px", width: "100%" }}
        onClick={(e) =>{e.stopPropagation(); setIsModalOpen(false)}}
      >
        Close
      </button>
    </Modal>
  );
};

export default ShowDonations;
