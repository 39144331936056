import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import AddCoins from "../modals/AddCoins";
import EditUserModal from "../modals/EditUser";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Footer from "../common/Footer";
import OrgLogo from "../../assets/Organization.png";
import IndLogo from "../../assets/Individual.png";
import AdmLogo from "../../assets/Admin.png";
import { MdContentCopy } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoMail } from "react-icons/io5";

const Ngo = () => {
  const navigate = useNavigate();
  const { ngoId } = useParams();
  const [showSend, setShowSend] = useState(false);
  const openSend = () => setShowSend(true);
  const closeSend = () => setShowSend(false);
  const userToken = localStorage.getItem("userToken");
  const [userData, setUserData] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [currency, setCurrency] = useState({});
  const [filter, setFilter] = useState({ status: "", type: "" });
  const [sortByDate, setSortByDate] = useState("desc");
  const [profile, setProfile] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const transactionsPerPage = 10;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastTransaction = (currentPage + 1) * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  let currentTransactions = userData?.transactions?.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/user`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response?.data?.data?.profileType !== "admin") {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userToken]);

  const toggleSortByDate = () => {
    setSortByDate(sortByDate === "asc" ? "desc" : "asc");
  };

  const loadCoin = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_API}/currency`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCurrency(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModal = (user) => {
    setShowEdit(true);
  };

  const closeModal = () => {
    setShowEdit(false);
  };

  useEffect(() => {
    const loadData = () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_API}/dashboard?transactionStatus=${filter.status}&transactionType=${filter.type}&userId=${ngoId}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => {});
    };
    loadCoin();
    loadData();
  }, [filter]);

  const sendBack = (val) => {
    Swal.fire({
      title: "Are you sure you want to reverse this transaction?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#3ca151",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_LOCAL_API}/revoke/${val._id}`,
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        };

        axios
          .request(config)
          .then((response) => {
            window.location.reload();
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("User canceled.");
      }
    });
  };

  const handleView = (id) => {
    window.open(`/Community/${id}`);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://demo.donor.money/register?referredby=${userData?.user?.referCode}`
    );
  };

  const handleMail = () => {
    window.open(
      `mailto:friend@example.com?subject=Donor Money Referral Link&body=Use my Referral link while creating Donor Money account: https://demo.donor.money/register?referredby=${userData?.user?.referCode}`
    );
  };

  const handleWp = () => {
    window.location.href = `https://wa.me/send?text= Use my Referral link while creating Donor Money account: https://demo.donor.money/register?referredby=${userData?.user?.referCode}`;
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div style={{ margin: "100px auto" }}>
        <h2 id="head_dash" style={{ margin: "30px 200px" }}>
          Dashboard - User View
        </h2>

        <div id="upper-box">
          <div
            style={{
              padding: "20px",
              borderRight: "1px solid #333",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img className="circular-image"
              src={
                userData?.user?.profilePic ||
                (userData?.user?.profileType === "admin"
                  ? AdmLogo
                  : userData?.user?.profileType === "individual"
                  ? IndLogo
                  : OrgLogo)
              }
              alt="profile"
              style={{ height: "60px", margin: "5px" }}
            />
            <h3>
              {userData?.user?.profileType === "admin" ||
              userData?.user?.profileType === "individual"
                ? `${userData?.user?.firstName} ${userData?.user?.lastName}`
                : userData?.user?.businessName}
            </h3>
          </div>

          <div className="ref-parent">
            <table style={{ width: "30%" }}>
              <thead>
                <tr>
                  <th style={{ backgroundColor: "transparent" }}>User Type</th>
                  <th style={{ backgroundColor: "transparent" }}>
                    Referral Code
                  </th>
                  {userData?.user?.profileType !== "individual" && (
                    <th style={{ backgroundColor: "transparent" }}>POC Name</th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {userData?.user?.profileType==="impactOrg"?"IMPACT ORG":userData?.user?.profileType.toUpperCase()}</td>
                  <td>{userData?.user?.referCode}</td>
                  {userData?.user?.profileType !== "individual" && (
                    <td>{`${userData?.user?.firstName} ${userData?.user?.lastName}`}</td>
                  )}
                </tr>
              </tbody>
            </table>
            <div
              style={{ border: "1px solid #333", padding: "10px" }}
              className="referral-code"
            >
              <h3>Share the Referral Link</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  disabled
                  value={`https://demo.donor.money/register?referredby=${userData?.user?.referCode}`}
                />
                <div style={{ marginBottom: "10px" }}>
                  <MdContentCopy
                    onClick={handleCopy}
                    style={{ cursor: "pointer" }}
                    size={26}
                  />{" "}
                  <IoMail
                    onClick={handleMail}
                    style={{ cursor: "pointer" }}
                    size={26}
                  />{" "}
                  <IoLogoWhatsapp
                    onClick={handleWp}
                    style={{ cursor: "pointer" }}
                    size={26}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shadow-box account-box" style={{ width: "80%" }}>
          <div className="account-details">
            <h3>
              Balance: ${" "}
              {(
                userData?.wallet?.walletPoint * currency?.value
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              {` `}
              <span id="today">
                (1.000 DM = ${currency?.value?.toFixed(2)})
              </span>
            </h3>
            <h3>
              No. of DM:{" "}
              {userData?.wallet?.walletPoint?.toLocaleString(undefined, {
                minimumFractionDigits: 3,
                maximumFractionDigits: 3,
              })}
            </h3>
          </div>
          <div className="action-buttons">
            <button onClick={openSend}>Give DM</button>
            <AddCoins
              showModal={showSend}
              closeModal={closeSend}
              userDetails={userData}
              userToken={userToken}
            />
            <button onClick={openModal}>Edit Profile</button>
            <EditUserModal
              userId={userData.user?._id}
              userToken={userToken}
              onClose={closeModal}
              showEdit={showEdit}
              isAdmin={true}
            />
            {/* <button>Download Data</button> */}
          </div>
        </div>

        <h3 style={{ textAlign: "center" }}>Recent Transactions</h3>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <div className="shadow-box" style={{ width: "80%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h3>
                <select
                  onChange={(e) =>
                    setFilter({ ...filter, status: e.target.value })
                  }
                >
                  <option value="">Status</option>
                  <option value="success">Success</option>
                  <option value="failed">Failed</option>
                  <option value="reversed">Reversed</option>
                  {/* Add more options as needed */}
                </select>

                <select
                  style={{ marginLeft: "5px" }}
                  onChange={(e) =>
                    setFilter({ ...filter, type: e.target.value })
                  }
                >
                  <option value="">Type</option>
                  <option value="Donation">Donation</option>
                  <option value="Grant">Grant</option>
                  <option value="Sale">Sale</option>
                  <option value="Purchase">Purchase</option>
                  <option value="Barter">Barter</option>
                  <option value="Gift">Gift</option>

                  {/* Add more options as needed */}
                </select>

                <button
                  type="button"
                  style={{ marginLeft: "5px" }}
                  onClick={toggleSortByDate}
                >
                  Sort by Date {sortByDate === "asc" ? "↓" : "↑"}
                </button>
              </h3>
            </div>
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Id</th>
                    <th>From</th>
                    <th>To</th>
                    <th> No. of DM</th>
                    <th>Market Value</th>
                    <th>Transaction Type</th>
                    <th>Transaction Status</th>
                    <th>Payment Mode</th>
                    <th>Reverse</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTransactions
                    ?.sort((a, b) => {
                      const dateA = moment(a.createdDate);
                      const dateB = moment(b.createdDate);
                      return sortByDate === "asc"
                        ? dateA - dateB
                        : dateB - dateA;
                    })
                    .map((tr, index) => (
                      <tr key={index}>
                        <td>
                          {moment(tr.createdDate).format("DD-MMM-YYYY hh:mm A")}
                        </td>
                        <td>{tr.transId ? tr.transId : tr._id}</td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleView(tr.senderId?.userName)}
                        >
                          {tr.senderId?.businessName
                            ? tr.senderId?.businessName
                            : `${tr.senderId?.firstName} ${tr.senderId?.lastName}`}
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleView(tr.recipientUserId?.userName)
                          }
                        >
                          {tr.recipientUserId?.businessName
                            ? tr.recipientUserId?.businessName
                            : `${tr.recipientUserId?.firstName} ${tr.recipientUserId?.lastName}`}
                        </td>
                        <td>
                          {tr.amount?.toLocaleString(undefined, {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                          })}
                        </td>
                        <td>
                          $
                          {(tr.amount * tr.currencyValue)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          ) || 0}
                        </td>
                        <td>{tr.transactionType}</td>
                        <td>{capitalizeFirstLetter(tr.transactionStatus)}</td>
                        <td>{tr.paymentMode}</td>
                        <td style={{ display: "flex", alignItems: "center" }}>
                          {tr.transactionStatus !== "reversed" &&
                            !tr.isReversed &&
                            tr.transactionStatus !== "failed" && (
                              <button onClick={() => sendBack(tr)}>
                                Reverse
                              </button>
                            )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={Math.ceil(
                  userData?.transactions?.length / transactionsPerPage
                )}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
        <p style={{ textAlign: "center" }}>
          *Market Value is based on the last traded price on the Donor Money
          &nbsp;{" "}
          <a href="https://www.donor.money/Impact-Exchange ">
            Impact Funding Exchange
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Ngo;
